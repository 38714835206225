// style presets
:root {
    // title
    --msv-account-landing-tile-heading-color: var(--msv-font-primary-color);
}

.eve-contracts-tile__heading {
    font-family: "Rubik-Bold";
    font-weight: 700;
    font-style: normal;
    font-size: var(--msv-h3-font-size-l);
    line-height: var(--msv-h3-line-height-l);
    padding-bottom: 22px;
    margin-bottom: 38px;
    border-bottom: 0.5px solid #C4C4C4;
    color: var(--msv-account-landing-tile-heading-color);
}

.eve-contracts-tile {
    width: 100%;
    margin-bottom: 48px;
  
    ul.contracts-table {
        padding-top: 14px;

        li {
            padding: 10px 30px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border: 1px solid $msv-gray-50;
            margin-bottom: -1px;
        }

        li:last-child {
            border-bottom-left-radius: 3px;
            border-bottom-right-radius: 3px;
        }

        .contracts-table-header {
            border-top-left-radius: 3px;
            border-top-right-radius: 3px;
            background-color: $msv-gray-50;
            font-size: 14px;
            font-weight: 700;
            text-transform: uppercase;
            letter-spacing: 0.03em;
            color: $msv-font-primary-color;
        }

        .contracts-table-row {
            background-color: #ffffff;
            min-height: 40px;
        }

        .contracts-col-1 {
            flex-basis: 22.5%;

            &--mobile {
                display: none;
            }

            @media screen and (max-width: $msv-breakpoint-m) {
                &--mobile {
                    display: flex;
                }
                &--desktop {
                    display: none;
                }
                flex-basis: 20%;
            }
        }

        .contracts-col-2 {
            flex-basis: 22%;
            padding-left: 10px;
            @media screen and (max-width: $msv-breakpoint-m) {
                flex-basis: 30%;
            }
        }

        .contracts-col-3 {
            flex-basis: 18%;
            padding-left: 10px;

            @media screen and (max-width: $msv-breakpoint-m) {
                flex-basis: 0%;
                display: none;
            }
        }

        .contracts-col-4 {
            flex-basis: 37.5%;
            padding-left: 10px;
            @media screen and (max-width: $msv-breakpoint-m) {
                flex-basis: 50%;
            }
        }
    }

    &__links {
        a {
            font-family: "Rubik-Regular";
            font-weight: 400;
            font-style: normal;
            font-size: var(--msv-link-font-size-m);
            line-height: var(--msv-link-line-height-m);
            color: var(--msv-link-font-color);
            text-decoration: underline;
            padding-top: 14px;
        }
    }
}