@media print {

    header,
    footer {
        display: none;
    }

    .msc-invoice-details__products picture,
    .msc-cart-line .msc-cart-line__product-image {
        display: none;
    }

    #main .default-container {
        max-width: unset;
        zoom: 50%;

        button.msc-invoice-details__header__actions__pay-invoice.msc-btn,
        .ms-order-details__sales-line-btn-buy-it-again-link {
            color: #000;
            padding: 6px;
        }

        .ms-order-details {
            .ms-order-details__payment-methods,
            .ms-order-details__address {
                padding: 20px 30px;
            }
        }
    }
}