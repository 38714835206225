.dropzone {
  height: 100px;
  width: 100%;
  border: 1px dashed lightgrey;
  display: flex;
  justify-content: center;
  align-items: center;
  //pointer-events: none;
  &__accept {
    border: 4px dashed $gardner-blue-1;
    background-color: rgba($gardner-blue-1, 0.2);
  }
  &__reject {
    border: 4px dashed $gardner-red-1;
    background-color: rgba($gardner-red-1, 0.2);
  }
}