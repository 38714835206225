.ms-allowable-freight {
    padding: 1em;
    
    &__heading {
        text-align: center;
        font-weight: 700;
        margin-top: 32px;
        border: 0.5px solid #C4C4C4;
        border-radius: 10px;
    }
    &__label {
        padding-bottom: 1em;
        text-align: center;
    }
    &__slider {
        flex-grow: 1;
        margin: 0 2%;
        height: 20px;
        border-radius: 10px;
        background-color: lightgrey;
        .progress-bar {
            height: 20px;
            border-radius: 10px;
        }
        &.gardner-red .progress-bar {
            background-color:  $gardner-red-1;
        }
        &.gardner-yellow .progress-bar {
            background-color:  $gardner-yellow-1;
        }
        &.gardner-green .progress-bar {
            background-color:  $gardner-green-1;
        }
    }
    &__message {
        padding-top: 1em;
        // displays a line between the slider and the error message
        // margin-top: 1em;
        // border-top: 0.5px solid $msv-gray-50;
    }
    .range-text {
        font-weight: bold;
    }
}
