
.eduerrors{
    color: #A80000;
    border: none; 
    border-radius: 10px;
    font-size: 16px;
    font-weight: normal;
    padding: 16px;
    background-color: #FFE7E7;
    margin: 1em 0;
}
.course-list{
    width: 100%;
    p.infoMessage, p.errMessage{
        font-size: 14px;
        padding: 6px;
        margin: 8px 0;
    }
    p.errMessage{
        color: #A80000;
        border: none; 
        border-radius: 10px;
        font-size: 16px;
        font-weight: normal;
        padding: 22px;
        background-color: #FFE7E7;
    }
    h5.list-group-item-heading{
        font-size: 1.2em;
        margin-bottom: 12px;
    }
    .btn{
        @include primary-button-light();
        display: block;
        margin-top: 24px;
    }
    table{
        border-collapse: collapse;
        thead{
            tr th{
                    border-bottom: none;
            }
            tr:first-child th{
                font-size: 18px;
                border-bottom: none;
                
            }
            tr:nth-child(2){
                background-color: #2D3034;
                color: #fff;
            }
        }
        tbody tr{
            &:first-child td{
                border-top: none;
            }
            td{
                .btn{
                    padding: 12px 30px;
                    display: inline-flex;
                    margin-top: auto;
                }
            }
            td:last-child {
                text-align: center;
            }
        }
    }
}