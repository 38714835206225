.advanced-notes-container {
    margin-top: 1rem;
    
    &__note-text {
        margin-bottom: .5rem;
        display: flex;
        background-color: $msv-gray-50;
        border: 1px solid $msv-gray-50;
        border-radius: 0.25rem;
        font-size: 1rem;
        color: $msv-font-primary-color;
        padding: 0.5rem 1rem;
        align-self: center;
    }

    &--print-view {
        display: none;
    }
}