.ms-checkout__guided-card {
    &-header {
        @media (min-width: $msv-breakpoint-m+1) and (max-width: $msv-breakpoint-l)  {
            width: 60%;
        }
    }
    .ms-checkout-section-container {
        .payment-selector {
            &__button-container {
                display: flex;
                place-content: space-between;
                margin-left: 10px;
                
                button {
                    @include primary-button-light();
                    
                    @media (max-width: $msv-breakpoint-xl)  {
                        padding: 12px 40px;
                    }

                }
                button.disabled {
                    cursor: auto!important;
                    &:hover {
                        box-shadow: none;
                    }
                }
                &.hide-buttons {
                    visibility: hidden;
                    height: 0;
                }

                @media (max-width: 1200px)  {
                    flex-direction: column;
                    margin-left: 0;
                    gap: 10px;
                }
            }
            &__select-payment-container {
                margin-top: -60px;
                text-align: end;
                &.hide-buttons {
                    margin-top: 0;
                }         
                button {
                    @include link-regular-m();
                    padding: $msv-checkout-edit-btn-padding;
                    margin-left: $msv-checkout-edit-btn-margin-left;
                    background: transparent;
                    border: none;

                    &:hover {
                        color: var(--msv-accent-hover-focus-color);
                    }
                }
            }
            &__save-container {
                margin-left: 0px;
                margin-top: 25px;
                button {
                    @include primary-button-light();
                }
                &.hide-buttons {
                    visibility: hidden;
                    height: 0;
                }
            }
        }
        .payment-selector__button-container + button.ms-checkout__guided-card-btn-save {
            background-color: aqua;

        }
        &.hide-payments {
            visibility: hidden;
            height: 0;
            margin-top: 0;
        }
        &__item {
            .hide-payment-module {
                visibility: hidden;
                height: 0;
            }
            .hide-save-button {
                display: none;
            }
        }

        .ms-checkout-payment-instrument__error {
            margin-bottom: 0;
            margin-top: 32px;
        }
    }

    button[aria-label="Change PAYMENT METHOD"] {
        display: none;
    }
    &-footer.hide-save-button {
        display: none;
    }
}

.eve-surcharge-payment-summary-line {
    .msc-invoice-summary {
        &__line-total {
            border: none;
            margin: 0;
        }
        &__label, &__value {
            font-family: "Rubik-Regular";
            font-weight: 400;
            font-size: var(--msv-body-font-size-m);
            line-height: var(--msv-body-line-height-m);
            color: var(--msv-body-font-color);
        }
    }
}