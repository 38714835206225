$quickOrder-margin-top: 50px;
$quickOrder-padding-top: 20px;
$quickOrder-padding-bottom: 20px;
$quickOrder-margin-bottom: 20px;
$quickOrder-margin-left: 20px;
$quickOrder-user-msg-padding: 20px;
$quickOrder-desc-margin-top: 12px;
$quickOrder-margin-left: 20px;
$quickOrder-drawer-button-padding-left: 0;
$quickOrder-drawer-button-padding-right: 0;
$quickOrder-drawer-expand-padding-right: 20px;
$quickOrder-addtocart-button-margin-top: 24px;
$quickOrder-addtocart-quickadd-button-margin-top: 31px;
$quickOrder-main-height: 48px;
$msv-quickOrder-border: 1px solid $msv-gray-300;
$msv-quickOrder-border-textbox: 0.5px solid $msv-gray-300;
$msv-quickOrder-alert-icon-margin: 8px;
$msv-quickOrder-alert-success-text-color: $msv-green-10;
$msv-quickOrder-alert-success-background-color: $msv-green-20;
$msv-quickOrder-mobile-title-font-weight: 700;
$msv-alert-line-margin-top: 2px;
$ms-quick-order-drawer-margin-top: 10px;
$ms-quick-order-drawer-margin-left: 6px;
$ms-quick-order-drawer-padding: 10px;
$ms-quick-order-content-form-main-addtocart-dektop-margin-left: 12px;
$ms-quick-order-content-form-main-addtocart-tab-margin-left: 5px;
$ms-quick-order-content-form-main-addtocart-dektop-input-width: 376px;
$ms-quick-order-content-form-main-addtocart-tab-input-width: 100%;
$ms-quick-order-content-form-main-product-margin-right: 11px;
$msv-quickOrder-maxwidth-textbox: 164px;
$msv-quickOrder-borderradius-textbox: 3px;
$ms-quick-order-content-form-main-button-padding: 12px 8px;
$msv-quickOrder-msc-dropdown-select-maxwidth: 164px;
$msv-quickOrder-quantity-maxwidth: 164px;
$ms-quick-order-msc-alert-padding: 22px;
$ms-quick-order-msc-alert-margin-bottom: 23px;
$ms-quick-order-msc-alert-border-radius: 10px;
$ms-quick-order-addtocart-button-border-radius: 25px;
$msv-quickOrder-borderradius-dropdown-select: 3px;
$msv-quick-order-alert-danger-margin-bottom: 8px;
$ms-quick-order-exclamation-triangle-padding-left: 2px;
$ms-quick-order-form-main__configure-margin-right: 8px;
$ms-quick-order-form-main__dropdown-margin-right: 11px;
$msv-quick-order-margin-bottom: 17px;
$ms-quick-order-heading-margin-right: 30px;
$msv-quickOrder-alert-error-color: $msv-red;
$msv-quickOrder-alert-icon-margin-right: 10px;
$msv-quickOrder-alert-margin-top: 12px;
$ms-quick-order-form-content-padding-top: 24px;
$msv-alert-icon-margin-right: 22px;

//style presets
:root {
    --msv-quickOrder-btn-bg: #{$msv-white};
    --msv-cart-line-group-border-color: #{$msv-gray-50};
}

.ms-quick-order__content-form-main {
    &-manufacturer {
        width: 300px;
        
        &-label {
            @include form-input-label();
        }

        &-dropdown {
            @include font-body-regular-s();
            height: 52px;
            background: linear-gradient(0deg, $msv-white, $msv-white), $msv-gray-100;
            width: 100%;
            border: $msv-quickOrder-border-textbox;
            border-radius: $msv-quickOrder-borderradius-dropdown-select;
            outline: none;
            padding: 16px 12px;
            order: 1;
    
            &:focus {
                border: $msv-quickOrder-border;
                outline: none;
            }
    
            @media (min-width: $msv-breakpoint-l) {
                max-width: $msv-quickOrder-msc-dropdown-select-maxwidth;
            }
        }
    }
}

@media screen and (max-width: $msv-breakpoint-m) {
    .ms-quick-order {
        &__content {
            &-form-main {
                &-manufacturer {
                    width: auto;
                    height: auto;
                    margin-bottom: $quickOrder-margin-bottom;
                    
                    &-dropdown {
                        width: 100%;
                    }
                }

            }
        }
    }
}
