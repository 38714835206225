.controls {

  &__columns {
    display: flex;
  }
  &__col-chip {
    @include font-body-regular-s();
    margin-right: 20px;
    padding: 8px 12px 8px 12px;
    position: relative;
    border-radius: 100px;
    border: none;
    align-items: center;
    text-decoration: none;
    background: rgba(245, 245, 245, 0.8);
    &--delete {
      @include add-icon($msv-times-circle, 'after')
    }
  }

}
