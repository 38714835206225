.ms-store-select__dealer_container {
    margin-left: auto;
    margin-right: auto;
    max-width: 1440px;
    padding-left: 60px;
    padding-right: 60px;
    border-bottom: none;

    .ms-store-select {
        // &__map {
        //     max-width: 1440px;
        // }
        // &__body_wrapper {
        //     display: flex;
        //     flex-direction: row;
        // }

        // &__store_container {
        //     width: 40%;
        //     min-height: 500px;
        // }

        &__found-locations {
            margin-bottom: 10px;
        }

        &__search {
            padding: 0;
            margin-bottom: 10px;
            box-shadow: none;

            &-form {
                padding-left: 0;
                padding-right: 0;
            }

            &-input {
                width: calc(100% - (52px + 4px + 35px))!important;
            }
            
            // &-see-all-stores {
            //     margin-top: 8px;
            // }
        }

        &__locations {
            border: 1px solid lightgray;
            border-radius: 5px 0 0 5px;
            padding: 14px;
        }

        &__location {
            border-radius: 5px;
            box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);
            &-line-item {
                &-header {
                    margin-bottom: 10px;
                }
                &-contact-info {
                    width: 100%;
                    margin-top: 8px;
                }
                &-store-hours {
                    display: none;
                }
            }
            &.selected {
                box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.25)!important;
                .ms-store-select__location-line-item-store-hours {
                    display: block;
                }
            }
        }

    }
}

.eve-map_container {
    max-width: 1440px;
    padding-left: 60px;
    padding-right: 60px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: row;
    height: 600px;

    .ms-store-select {
        &__dealer_container {
            width: 40%;
            max-width: 40%;
            padding: 0;
        }
        &__found-locations {
            height: 20px;
        }
        &__locations {
            // 100% - 30px for found locations text and margin, - 29px for margin on __locations and - 52px for search box and margin
            max-height: calc(100% - 20px - 29px - 62px);
            min-height: calc(100% - 20px - 29px - 62px);
            border: 1px solid lightgray;
            border-radius: 5px 0 0 5px;
            padding: 10px;

            // .ms-store-select__location-line-item-contact-info {
            //     width: 100%;
            //     margin-right: 10px;
            // }
        }

        &__location {
            border-radius: 5px;
            box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);
            &-line-item {
                &-header {
                    margin-bottom: 10px;
                }
                &-contact-info {
                    width: 100%;
                    margin-top: 8px;
                }
                &-store-hours {
                    display: none;
                }
            }
            &.selected {
                box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.25)!important;
                .ms-store-select__location-line-item-store-hours {
                    display: block;
                    .ms-store-select__location-line-header {
                        margin: 13px 0;
                    }
                    .ms-store-select__store-hours-details {
                        flex-direction: column;
                        margin-bottom: 10px;

                        &-group {
                            display: flex;
                            flex-direction: column;
                            margin-bottom: 10px;
                            border-bottom: 1px solid black;

                            &-mfr-code {
                                display: flex;
                                margin-right: 50px;
                                
                                &--data {
                                    font-family: "Rubik-Bold";
                                    font-weight: 500;
                                    font-style: normal;
                                    font-size: var(--msv-body-font-size-s);
                                    line-height: var(--msv-body-line-height-s);
                                    color: var(--msv-body-font-color);
                                }
                            }

                            &-contract-code {
                                display: flex;
                                
                                &--heading {
                                    margin-right: 10px;
                                    font-family: "Rubik-Bold";
                                    font-weight: 500;
                                    font-style: normal;
                                    font-size: var(--msv-body-font-size-s);
                                    line-height: var(--msv-body-line-height-s);
                                    color: var(--msv-body-font-color);
                                }
                            }
                            
                            &-desc {
                                display: flex;
                                
                                &--heading {
                                    margin-right: 10px;
                                    font-family: "Rubik-Bold";
                                    font-weight: 500;
                                    font-style: normal;
                                    font-size: var(--msv-body-font-size-s);
                                    line-height: var(--msv-body-line-height-s);
                                    color: var(--msv-body-font-color);
                                }
                            }

                            // &-notes {
                            //     display: flex;
                            
                            //     &--heading {
                            //         margin-right: 10px;
                            //         font-family: "Rubik-Bold";
                            //         font-weight: 500;
                            //         font-style: normal;
                            //         font-size: var(--msv-body-font-size-s);
                            //         line-height: var(--msv-body-line-height-s);
                            //         color: var(--msv-body-font-color);
                            //     }
                            // }
                        }
                    }
                }
            }
        }
    }
    .ms-map {
        &__body {
            width: 60%;
            margin-left: 20px;
            border: 1px solid lightgray;
            border-radius: 5px;
        }
    }
}
