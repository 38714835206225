.eve-reports-export-wrapper {
    min-height: 450px;
    &__heading{
        font-family: 'Rubik-Bold';
        font-weight: 700;
        font-style: normal;
        font-size: var(--msv-h3-font-size-l);
        line-height: var(--msv-h3-line-height-l);
        color: var(--msv-font-primary-color);
        margin-bottom: 40px;
    }

    &-table {
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        &-row {
            padding: 12px 0px 20px;
            place-content: space-between;
            flex-direction: column;
            border-radius: 5px;
            display: flex;
            align-items: center;
            margin: 0 7px;
            @media (max-width: 1080px) {
                &:not(:last-child) {
                    margin-bottom: 20px;
                }
            }
            min-width: 300px;
            background-color: #ffffff;
            box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);
        
            &__heading {
                font-family: "Rubik-Bold";
                font-weight: 700;
                font-style: normal;
                font-size: var(--msv-body-font-size-m);
                line-height: var(--msv-body-line-height-m);
                color: var(--msv-body-font-color);
            }
            
            &__buttons {
                display: flex;
                flex-direction: column;
                padding: 0px 24px;

                &--download,
                &--downloadAsCSV,
                &--downloadAsExcel {    
                    @include primary-button-light;
                    padding: 4px 24px;
                    min-width: 0;
                    margin-top: 16px;

                    &:disabled {
                        cursor: initial;
                        &:hover {
                            box-shadow: none;
                        }
                    }
                }

            }
            
            &__dropdown {
                width: 100%;
                padding: 10px 24px 0;
                .go2139093995 * {
                    transition: none;
                    &.gray {
                        color: $msv-text-color;
                    }
                }
                .dropdown-container {
                    border: #DFDFDF solid 1px;
                    border-radius: 4px;
                    .dropdown-heading-value {
                        padding: 10px 5px;
                    }
                    .dropdown-content {
                        background-color: #ffffff;
                        box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);
                        .select-panel {
                            text-align: left;
                            label {
                                padding: 10px 5px;
                                &:hover {
                                    background-color: var(--msv-mini-cart-count-background-color);
                                    color: var(--msv-header-font-color);
                                }
                                div { 
                                    display: flex;
                                }
                            }
                            input {
                                accent-color: $msv-link-color;
                            }
                        }
                    }
                }
                select {
                    width: inherit;
                    border: #DFDFDF solid 1px;
                    border-radius: 4px;
                    padding: 10px 5px;
                    color: $msv-text-color;
                    option {
                        color: inherit;
                    }
                }
            }
        }
            
        @media (max-width: $msv-breakpoint-m) {
            flex-direction: column;
        }            
    }
    &__modal {
            .msc-modal__content {
                border-radius: 10px;
            }
            &--error-message {
                .msc-modal__content{
                    background-color: $msv-pink;
                    border: 1px solid $msv-red;
                    border-radius: 10px;
                }
            }
    }
    &__modal-header {
        &-wrapper {
            display: flex;
            
        }
    }
    &__modal-body {
        padding: 30px 0;
        font-size: 20px;
    }
}